import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;
const { Option } = Select;

interface DetailFormProps {
  id: string;
  initialGrade?: string;  // Add initial values
  initialSubject?: string;
  initialStandard?: string;
  setResponse: React.Dispatch<React.SetStateAction<string>>;
  setTopic: React.Dispatch<React.SetStateAction<string>>;
  setGrade: React.Dispatch<React.SetStateAction<string>>;
  setStandard: React.Dispatch<React.SetStateAction<string>>;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  setExportLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalDetails: React.Dispatch<React.SetStateAction<string>>;
  setQuestionComplexity: React.Dispatch<React.SetStateAction<string>>;
}

const DetailForm: React.FC<DetailFormProps> = ({
  id,
  setResponse,
  setTopic,
  setGrade,
  setStandard,
  setSubject,  // Added
  onSubmit,
  setExportLoading,
  setAdditionalDetails,
  setQuestionComplexity,
}) => {
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  const [topic, internalSetTopic] = useState('');
  const [grade, internalSetGrade] = useState('');
  const [standard, internalSetStandard] = useState('');
  const [subject, internalSetSubject] = useState('');  // Added
  const [additionalDetails, setInternalAdditionalDetails] = useState('');
  const [questionComplexity, internalSetQuestionComplexity] = useState('mix');
  const [loading, setLoading] = useState(false);
  const [topicError, setTopicError] = useState<string | null>(null);
  const [gradeError, setGradeError] = useState<string | null>(null);
  const [standardError, setStandardError] = useState<string | null>(null);
  const [subjectError, setSubjectError] = useState<string | null>(null);  // Added
  const websocketRef = useRef<WebSocket | null>(null);
  const latestResponseRef = useRef<string>('');

    // Fetch and set initial values
    useEffect(() => {
      const fetchUserData = async () => {
        if (auth.currentUser) {
          try {
            const userDoc = doc(db, 'users', auth.currentUser.uid);
            const userSnapshot = await getDoc(userDoc);
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              internalSetGrade(userData.grade || '');
              setGrade(userData.grade || '');
              internalSetSubject(userData.subject || '');
              setSubject(userData.subject || '');
              internalSetStandard(userData.standard || '');
              setStandard(userData.standard || '');
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        }
      };
  
      fetchUserData();
    }, [auth.currentUser]);

  useEffect(() => {
    setExportLoading(loading);
  }, [loading]);

  const handleSubmit = () => {
    let hasError = false;
    if (!topic) {
      setTopicError('Topic is required');
      hasError = true;
    } else {
      setTopicError(null);
    }

    if (!grade) {
      setGradeError('Grade is required');
      hasError = true;
    } else {
      setGradeError(null);
    }

    if (!standard) {
      setStandardError('Standard is required');
      hasError = true;
    } else {
      setStandardError(null);
    }

    if (!subject) {
      setSubjectError('Subject is required');
      hasError = true;
    } else {
      setSubjectError(null);
    }

    if (hasError) return;

    setResponse('');
    setLoading(true);

    const wsUri = process.env.REACT_APP_WS_URI || '';
    if (!wsUri) {
      setTopicError('WebSocket URI is not configured.');
      setLoading(false);
      return;
    }

    const websocket = new WebSocket(wsUri);
    websocketRef.current = websocket;

    websocket.onopen = () => {
      const message = JSON.stringify({
        topic,
        grade,
        standard,
        subject,  // Added
        additionalDetails,
        questionComplexity,
        card_id: id,
      });
      websocket.send(message);
    };

    websocket.onmessage = (event) => {
      const data = event.data.replaceAll('^^^', '\n');

      setResponse((prevResponse) => {
        const updatedResponse = prevResponse + data;
        latestResponseRef.current = updatedResponse;
        return updatedResponse;
      });
    };

    websocket.onerror = () => {
      setTopicError('An error occurred during the request.');
      setLoading(false);
    };

    websocket.onclose = () => {
      setLoading(false);
      console.log('Final Response:', latestResponseRef.current);
    };

    onSubmit();
  };

  const handleTopicChange = (value: string) => {
    internalSetTopic(value);
    setTopic(value);
  };

  const handleGradeChange = (value: string) => {
    internalSetGrade(value);
    setGrade(value);
  };

  const handleStandardChange = (value: string) => {
    internalSetStandard(value);
    setStandard(value);
  };

  const handleSubjectChange = (value: string) => {  // Added
    internalSetSubject(value);
    setSubject(value);
  };

  const handleAdditionalDetailsChange = (value: string) => {
    setInternalAdditionalDetails(value);
    setAdditionalDetails(value);
  };

  const handleQuestionComplexityChange = (value: string) => {
    internalSetQuestionComplexity(value);
    setQuestionComplexity(value);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <div className="login-form-input-section">
        <Form.Item validateStatus={topicError ? 'error' : ''} help={topicError}>
          <Input
            className="login-form-input"
            value={topic}
            onChange={(e) => handleTopicChange(e.target.value)}
            placeholder="Topic"
          />
        </Form.Item>
      </div>
      <div className="login-form-input-section">
        <Form.Item validateStatus={gradeError ? 'error' : ''} help={gradeError}>
          <Select
            className="login-form-select-input"
            value={grade}
            onChange={handleGradeChange}
            placeholder="Select grade"
          >
            <Option value="" disabled>
              Grade
            </Option>
            {Array.from({ length: 8 }, (_, i) => (
              <Option key={i + 5} value={i + 5}>
                Class {i + 5}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="login-form-input-section">
        <Form.Item validateStatus={subjectError ? 'error' : ''} help={subjectError}>
          <Input
            className="login-form-input"
            value={subject}
            onChange={(e) => handleSubjectChange(e.target.value)}
            placeholder="Subject"
          />
        </Form.Item>
      </div>
      <div className="login-form-input-section">
        <Form.Item validateStatus={standardError ? 'error' : ''} help={standardError}>
          <Input
            className="login-form-input"
            value={standard}
            onChange={(e) => handleStandardChange(e.target.value)}
            placeholder="Any standard worldwide like CBSE, ICSE, CCSS etc"
          />
        </Form.Item>
      </div>
      <div className="login-form-input-section">
        <Form.Item>
          <TextArea
            className="login-form-input"
            value={additionalDetails}
            onChange={(e) => handleAdditionalDetailsChange(e.target.value)}
            placeholder="Add any other specifics, sub-topics or details for your topic"
            rows={4}
          />
        </Form.Item>
      </div>
      <div className="login-form-input-section">
        {(id === 'MCQ' || id === 'assignment') && (
          <Form.Item>
            <Select
              className="login-form-select-input"
              value={questionComplexity}
              onChange={handleQuestionComplexityChange}
              placeholder="Select question complexity"
            >
              <Option value="" disabled>
                Question Complexity
              </Option>
              <Option value="easy">Easy</Option>
              <Option value="medium">Medium</Option>
              <Option value="complex">Complex</Option>
              <Option value="mix">Mix of all</Option>
            </Select>
          </Form.Item>
        )}
      </div>
      <div className="row justify-content-lg-center">
        <div className="col-md-12">
          <div className="row justify-content-md-center">
            <div className="col-md-8">
              <Form.Item>
                <Button
                  className="login-from-login-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Submit &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default DetailForm;