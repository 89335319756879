import React, { useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { Layout } from 'antd';
import './styles.css';
import LoginForm from './LoginForm';
import AdditionalInfoForm, { AdditionalInfo } from './AdditionalInfoForm';

const { Content } = Layout;

const Login: React.FC = () => {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    name: '',
    city: '',
    grade: '',
    subject: '',
    school: '',
    standard: ''
  });
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState<boolean>(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState<boolean>(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');

  useEffect(() => {
    const handleBackButton = () => {
      window.location.href = 'https://myparaeducator.com/';
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!user.emailVerified) {
          const intervalId = setInterval(async () => {
            await user.reload();
            if (user.emailVerified) {
              clearInterval(intervalId);
              setSuccess('Email verified successfully. Please log in.');
              setIsRegistering(false);
              setShowAdditionalInfo(false);
              await signOut(auth);
            }
          }, 5000);

          return () => clearInterval(intervalId);
        } else {
          // Fetch additional info from Firestore
          const userDoc = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userDoc);

          if (userSnapshot.exists()) {
            const info = userSnapshot.data() as AdditionalInfo;
            // Check if required fields are missing
            if (!info.grade || !info.subject || !info.standard || info.name === "Default Name") {
              setAdditionalInfo({
                name: info.name || '',
                city: info.city || '',
                grade: info.grade || '',
                subject: info.subject || '',
                school: info.school || '',
                standard: info.standard || ''
              });
              setShowAdditionalInfo(true);
              setError('Please complete all required information to continue.');
            } else {
              setAdditionalInfo(info);
              setShowAdditionalInfo(false);
              setSuccess('Welcome back!');
              navigate('/views');
            }
          } else {
            setShowAdditionalInfo(true);
          }
        }
      }
    });

    return () => unsubscribe();
  }, [auth, db, navigate]);

  const logErrorToFirestore = async (error: any, additionalInfo: string = '') => {
    const errorDoc = doc(db, 'loginErrors', new Date().toISOString());
    await setDoc(errorDoc, {
      message: error.message,
      stack: error.stack,
      additionalInfo,
      timestamp: new Date().toISOString(),
    });
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Google login successful');
      setError(null);
    } catch (error) {
      setError('Error logging in with Google.');
      console.error('Error logging in with Google:', error);
      await logErrorToFirestore(error, 'Google login');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(auth, values.email, values.password);
      if (!result.user.emailVerified) {
        const emailVerificationError = new Error('Please verify your email before logging in.');
        setError(emailVerificationError.message);
        await logErrorToFirestore(emailVerificationError, 'Email not verified');
        await auth.signOut();
        return;
      }
      console.log('Email login successful');
      setError(null);
    } catch (error) {
      setError('Error logging in with email.');
      console.error('Error logging in with email:', error);
      await logErrorToFirestore(error, 'Email login');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailRegister = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      const result = await createUserWithEmailAndPassword(auth, values.email, values.password);
      await sendEmailVerification(result.user);
      setVerificationEmailSent(true);
      setSuccess('Verification email sent. Please check your inbox.');
      console.log('Email registration successful and verification email sent');
      setError(null);
    } catch (error) {
      setError('Error registering with email.');
      console.error('Error registering with email:', error);
      await logErrorToFirestore(error, 'Email registration');
    } finally {
      setLoading(false);
    }
  };

  const handleAdditionalInfoSubmit = async (info: AdditionalInfo) => {
    const user = auth.currentUser;
    if (user && !user.emailVerified) {
      setError('Please verify your email before submitting additional information.');
      return;
    }

    if (!info.grade || !info.subject || !info.standard) {
      setError('Grade, subject, and standard are required fields.');
      return;
    }

    const userDoc = doc(db, 'users', auth.currentUser!.uid);
    try {
      await setDoc(userDoc, info);
      console.log('Additional info submitted');
      setSuccess('Additional info submitted successfully!');
      setError(null);
      navigate('/views');
    } catch (error) {
      setError('Error submitting additional info.');
      console.error('Error submitting additional info:', error);
      await logErrorToFirestore(error, 'Submitting additional info');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
      setSuccess('Password reset email sent. Please check your inbox.');
      setIsForgotPasswordModalVisible(false);
      setForgotPasswordEmail('');
    } catch (error) {
      setError('Error sending password reset email.');
      console.error('Error sending password reset email:', error);
      await logErrorToFirestore(error, 'Forgot password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <div>
      <Content>
        {!showAdditionalInfo ? (
          <LoginForm
            error={error}
            success={success}
            loading={loading}
            showAdditionalInfo={showAdditionalInfo}
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            isRegistering={isRegistering}
            setIsRegistering={setIsRegistering}
            handleEmailLogin={handleEmailLogin}
            handleEmailRegister={handleEmailRegister}
            handleGoogleLogin={handleGoogleLogin}
            handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
            isForgotPasswordModalVisible={isForgotPasswordModalVisible}
            setIsForgotPasswordModalVisible={setIsForgotPasswordModalVisible}
            forgotPasswordEmail={forgotPasswordEmail}
            setForgotPasswordEmail={setForgotPasswordEmail}
            handleForgotPassword={handleForgotPassword}
          />
        ) : (
          <AdditionalInfoForm
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
          />
        )}
      </Content>
    </div>
    </>
  );
};

export default Login;