import React from 'react';
import { Form, Input, Button, Typography, Row, Col, Affix, Dropdown, Menu, Select } from 'antd';
import { StarFilled, ArrowLeftOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from '@firebase/auth';
import TeacherProfileImage from './assets/images/teacher-profile-right-side-image.png'
import LoginFromBackgroundImage from './assets/images/login-from-background-image.png'
import GradeIcon from './assets/images/grade-icon.png'
import UserIcon from './assets/images/user-icon.png'
import CityIcon from './assets/images/city-icon.png'
import SubjectIcon from './assets/images/subject-icon.png'
import SchoolIcon from './assets/images/school-icon.png'

const { Option } = Select;

export interface AdditionalInfo {
  name: string;
  city: string;
  grade: string;
  subject: string;
  school: string;
  standard: string;
}

interface AdditionalInfoFormProps {
  additionalInfo: AdditionalInfo;
  setAdditionalInfo: React.Dispatch<React.SetStateAction<AdditionalInfo>>;
  handleAdditionalInfoSubmit: (info: AdditionalInfo) => void;
}

const AdditionalInfoForm: React.FC<AdditionalInfoFormProps> = ({
  additionalInfo,
  setAdditionalInfo,
  handleAdditionalInfoSubmit
}) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log('Input change -', 'Field:', name, 'Value:', value);
    setAdditionalInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (value: string) => {
    console.log('Grade selection changed to:', value);
    setAdditionalInfo(prev => ({
      ...prev,
      grade: value
    }));
  };

  const handleSubmit = () => {
    console.log('Submit button clicked');
    console.log('Current form data:', additionalInfo);
    
    // Validate required fields
    const requiredFields = ['name', 'grade', 'subject', 'standard'];
    const missingFields = requiredFields.filter(field => !additionalInfo[field as keyof AdditionalInfo]);
    
    if (missingFields.length > 0) {
      console.log('Missing required fields:', missingFields);
      return;
    }

    console.log('All required fields present, proceeding with submission');
    handleAdditionalInfoSubmit(additionalInfo);
  };

  const handleLogout = async () => {
    try {
      console.log('Attempting logout');
      await signOut(auth);
      console.log('Logout successful');
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  console.log('Rendering form with data:', additionalInfo);

  const userName = auth.currentUser?.displayName || 'User';
  const userInitials = userName ? userName.split(' ').map(name => name[0]).join('') : 'U';

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<ArrowLeftOutlined />} onClick={() => navigate('/views')}>
        Back
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="login-form-section additionalinfo-form-section">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="login-from-image-total-section">
          <div className="row align-items-center login-from-row-section">
            <div className="col-md-6">
              <Form className='login-form-card'>
                <Typography.Title className='login-from-card-title' level={4}>
                  Teacher Profile
                </Typography.Title>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={UserIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Input 
                      className='login-form-input' 
                      name="name" 
                      value={additionalInfo.name} 
                      onChange={handleChange} 
                      placeholder='Name'
                    />
                  </Form.Item>
                </div>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={CityIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Input 
                      className='login-form-input' 
                      name="city" 
                      value={additionalInfo.city} 
                      onChange={handleChange} 
                      placeholder='City'
                    />
                  </Form.Item>
                </div>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={GradeIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Select
                      className='login-form-input'
                      value={additionalInfo.grade}
                      onChange={handleSelectChange}
                      placeholder='Select Grade'
                    >
                      <Option value="K">Kindergarten</Option>
                      <Option value="1">1st Grade</Option>
                      <Option value="2">2nd Grade</Option>
                      <Option value="3">3rd Grade</Option>
                      <Option value="4">4th Grade</Option>
                      <Option value="5">5th Grade</Option>
                      <Option value="6">6th Grade</Option>
                      <Option value="7">7th Grade</Option>
                      <Option value="8">8th Grade</Option>
                      <Option value="9">9th Grade</Option>
                      <Option value="10">10th Grade</Option>
                      <Option value="11">11th Grade</Option>
                      <Option value="12">12th Grade</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={SubjectIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Input 
                      className='login-form-input' 
                      name="subject" 
                      value={additionalInfo.subject} 
                      onChange={handleChange} 
                      placeholder='Subject'
                    />
                  </Form.Item>
                </div>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={SubjectIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Input 
                      className='login-form-input' 
                      name="standard" 
                      value={additionalInfo.standard} 
                      onChange={handleChange} 
                      placeholder='Educational Standard (e.g., Common Core)'
                    />
                  </Form.Item>
                </div>

                <div className="login-form-input-section">
                  <div className="input-field-icon">
                    <img src={SchoolIcon} alt="" />
                  </div>
                  <Form.Item required>
                    <Input 
                      className='login-form-input' 
                      name="school" 
                      value={additionalInfo.school} 
                      onChange={handleChange} 
                      placeholder='School'
                    />
                  </Form.Item>
                </div>

                <Button 
                  className='login-from-login-btn' 
                  type="primary" 
                  block 
                  onClick={handleSubmit}
                >
                  Submit  &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
                </Button>
              </Form>
            </div>
            <div className="col-md-6">
              <div className="login-form-right-side-image">
                <img src={TeacherProfileImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdditionalInfoForm;